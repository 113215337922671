
import {defineComponent, onMounted, ref} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import {redirectToRouteWithName} from "@/buying-teams/helpers/UrlHelper";
import {useRoute} from "vue-router";
import {Actions} from "@/store/enums/StoreEnums";
import { createAndOpenLink, getPlatform } from "@/core/helpers/GlobalHelper";
import { PlatformEnum } from "@/store/enums/PlatformEnum";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const { query } = useRoute();
    const { t } = useI18n()
    const confirmPassword = "";
    const password = "";

    onMounted(async () => {
      if (!query.email || !query.code) {
        redirectToRouteWithName("sign-in");
      } else {
        let platform: string | null = getPlatform();

        if (platform === PlatformEnum.IOS) createAndOpenLink(`com.buyingteams://password?code=${query.code}&email=${query.email}`)
        else if (platform === PlatformEnum.ANDROID) createAndOpenLink(`com.buyingteams://password?code=smth&email=smth`)
      }
    });

    const submitButton = ref<HTMLButtonElement | null>(null);

    const createPassword = Yup.object().shape({
      password: Yup.string()
        .required(t('Password is a required field')),
      confirmPassword: Yup.string()
        .required(t('Confirm Password is a required field'))
        .oneOf([Yup.ref('password'), null], t('Passwords must match'))
    });

    const onSubmitCreatePassword = async (values) => {
      if (submitButton.value) {
        submitButton.value!.disabled = true;
        submitButton.value?.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.RESET_PASSWORD, {
        email: query.email,
        verification_code: query.code,
        password: values.password
      }).finally(() => {
        submitButton.value!.disabled = false;
        submitButton.value?.removeAttribute("data-kt-indicator");
      });
    }

    return {
      onSubmitCreatePassword,
      createPassword,
      submitButton,
      password,
      confirmPassword
    };
  },
});
